import { createBrowserHistory } from 'history';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

const customHistory = createBrowserHistory();

interface IAppContainerProps {
    routes: any
    store: any
}

export class AppContainer extends React.Component<IAppContainerProps> {
    public render(){
        const { store, routes } = this.props;
        return(
            <Provider store={store}>
                <Router history={ customHistory }>
                    { routes }
                </Router>
            </Provider>
        )
    }
}

export default AppContainer;