import * as React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

// Main Container/jsx Displayed on the page
const HomeContainer = Loadable({
    loader: () => import('./containers/HomeContainer'),
    loading: () => null
});

// Lazy load all child routes and place in Route component underneath parent route
const TermsAndConditionsContainer = Loadable({
    loader: () => import('./routes/TermsAndConditions'),
    loading: () => null
});



export default function (store: any, props: any) {
    // call Register Reducer here
    const match = props.match;
    return (
        <Switch>
            <Route exact={true} path={match.url} component={HomeContainer} />
            <Route path={`/terms`} component={TermsAndConditionsContainer} />
        </Switch>
    );
}
