import { Reducer } from 'redux';
import { IApplicationState } from '../common/modules/app/types';
import { IEventState } from "../routes/Event/types";

export const EVENT_DETAILS_KEY = 'event_details';
export const APP_REDUCER_KEY = 'app';

export interface IFullStorageShape {
    [APP_REDUCER_KEY]: IApplicationState,
    [EVENT_DETAILS_KEY]: IEventState
}

export type StoreShape = Partial<IFullStorageShape>;
export type NamespaceKey = keyof StoreShape;

export type ReducerMap = Partial<
    { [k in NamespaceKey]: Reducer<IFullStorageShape[k]> }
>;