import { IApplicationState, ApplicationStateActions } from './types';


export const initialState: IApplicationState = {
    userName: '',
    authenticated: false
}

export default function reducer(
    state: IApplicationState = initialState,
    action: ApplicationStateActions
) {
    return state;
}