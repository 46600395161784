// @ts-nocheck
import { combineReducers, Store } from 'redux';
import { ReducerMap, StoreShape } from './app.types';
import appReducer from '../common/modules/app/appReducer';


let asyncReducers: ReducerMap = {};

export const makeRootReducer = () => {
    const reducer = combineReducers({
        app: appReducer,
        ...asyncReducers
    });

    return reducer;
}

export function registerReducer(store: Store<StoreShape>, newReducers: ReducerMap): Store<StoreShape> {
    asyncReducers = { ...asyncReducers, ...newReducers };
    store.replaceReducer(makeRootReducer());
    return store;
}