import * as React from 'react';
import Loadable from 'react-loadable';
import eventReducer from './modules/eventReducer';
import { EVENT_DETAILS_KEY } from '../../store/app.types';
import { registerReducer } from "../../store/reducers";
import {Route, Switch} from "react-router";

const EventContainer = Loadable({
    loader: () => import('./containers/EventContainer'),
    loading: () => null
});

export default function (store: any, props: any) {
    registerReducer( store, { [EVENT_DETAILS_KEY]: eventReducer });
    const match = props.match;
    return(
        <Switch>
                <Route path={`${match.url}`}
                       render={() => <EventContainer {...store} {...props} />} />
        </Switch>

    );
}
