import * as React from 'react';
import { AXSBlock } from '@axs/axsui-axsblock';
import { AXSFlex } from '@axs/axsui-axsflex';
// import Header from '../common/components/Header/';
import Footer from "../common/components/Footer";
import styled from 'styled-components';
import { withRouter } from "react-router";

interface PageLayoutProps {
    location: any;
    children: any;
}

export class PageLayout extends React.Component<PageLayoutProps> {
    public render(){
        const { children } = this.props;

        const AXSFlexExtended = styled(AXSFlex)`
            min-height: 100vh;
        `;

        const AXSBlockExtended = styled(AXSBlock)`
            flex: 1;
        `;

        return(
            <AXSFlexExtended flexDirection="column">
                {/*<Header />*/}
                <AXSBlockExtended className='page-layout__viewport'>
                    { children }
                </AXSBlockExtended>
                 <Footer />
            </AXSFlexExtended>
        );
    }
}
export default withRouter(PageLayout as any);
