// @ts-nocheck
import { EventTypes, IEventState, FetchEventSuccess, EventAction } from '../types';

export const initialState: IEventState = {
    isLoading: false,
    error: false,
    event: {
        id: "",
        vividseatsId: "",
        title: "",
        datetime: "",
        onsaleDateTime: "",
        dateOnly: false,
        imageUrl: "",
        majorCategoryId1: "",
        majorCategoryId1Label: "",
        minorCategoryId1: "",
        minorCategoryId1Label: "",
        venue: {
            "title": "",
            "city": "",
            "state": "",
            "country": "",
            "venueId": "",
            "postalCode": ""
        },
        ticketing: {
            "statusId": "",
            "status": ""
        }
    }
}

const ACTION_HANDLERS = {
    [EventTypes.FETCH_BEGIN]: (state: IEventState) => {
        return Object.assign({}, state, { isLoading: true});
    },

    [EventTypes.FETCH_SUCCESS]: (state: IEventState, action: FetchEventSuccess) => {
        return Object.assign({}, state, {isLoading: false, event: action.payload.event});
    },

    [EventTypes.FETCH_FAILURE]: (state: IEventState) => {
        return Object.assign({}, state, { isLoading: false, error: true});
    }

}

export default function reducer(state: IEventState = initialState, action: EventAction){
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}