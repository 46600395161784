import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { AppContainer } from './containers/AppContainer';
import createStore from './store/createStore';

const initialState =  {};
const store = createStore(initialState);
const mountNode = document.getElementById('root');

const root = createRoot(mountNode!);

const render = () => {
  const routes = require('./routes/index').default(store);

  root.render(
    <AppContainer store={ store } routes={ routes } />);
};

render();
