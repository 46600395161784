import PageLayout from '../layouts/PageLayout';
import Home from './Home';
import Event from './Event';
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

export const createRoutes = (store: any) => (
    <PageLayout>
        <Switch>
            <Route path='/event/:id' render={(props) => Event(store, props)} />
            <Route path='/' render={(props) => Home(store, props)} />
            <Redirect to="/" />
        </Switch>
    </PageLayout>
);

export default createRoutes;
