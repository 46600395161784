// @ts-nocheck
import { createStore as createReduxStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { makeRootReducer } from './reducers';
import thunk from 'redux-thunk';

const createStore = (initialState = {}) => {
    const logger = createLogger({
        logErrors: true,
        collapsed: true
    });

    let middleware: any[] = [thunk];
    if(process.env.NODE_ENV === 'development'){
        middleware = [thunk, logger];
    }

    const enhancers: never[] = [];
    const composeEnhancers = composeWithDevTools({});

    const store = createReduxStore(
        makeRootReducer(),
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware),
            ...enhancers
        )
    );

    return store;
}

export default createStore