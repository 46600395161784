import { AnyAction } from "redux";

export interface IEventState {
    isLoading: boolean,
    error: boolean,
    event: IEventResponse
}

export interface ITicketing {
    statusId: string,
    status: string
}

export interface IVenue {
    title: string,
    city: string,
    state: string,
    country: string,
    venueId: string,
    postalCode: string
}

export interface IEventResponse {
    id: string,
    vividseatsId: string,
    title: string,
    datetime: string,
    dateOnly: boolean,
    onsaleDateTime: string,
    imageUrl: string,
    majorCategoryId1: string,
    majorCategoryId1Label: string,
    minorCategoryId1: string,
    minorCategoryId1Label: string,
    venue: IVenue,
    ticketing: ITicketing
}

export const enum EventTypes {
    FETCH_BEGIN = 'FETCH_BEGIN',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_FAILURE = 'FETCH_FAILURE',
}

export interface FetchEventBegin extends AnyAction {
    type: EventTypes.FETCH_BEGIN
}

export interface FetchEventSuccess extends AnyAction {
    type: EventTypes.FETCH_SUCCESS,
    payload: any
}

export interface FetchEventFailure extends AnyAction {
    type: EventTypes.FETCH_FAILURE
}

export type EventAction = any;