import * as React from 'react';
import { AXSBlock } from "@axs/axsui-axsblock";
import styled from 'styled-components';
import { Link } from "react-router-dom";

const FooterElement = styled.footer`
    font-size: 14px;
    color: #aaaaaa;
    padding: 40px;
    background-color: #f6f6f6;
    
    a {
        color: #929292
    }
`;

const FooterTitle = styled(AXSBlock)`
    font-weight: bold;
`;

const LayoutWrapper = styled(AXSBlock)`
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
`;


const LinkElement = styled(Link)`
    text-decoration: underline;
`;

const ExternalLinkElement = styled.a `
    text-decoration: underline;
`;

const Footer = () => (
    <FooterElement>
        <LayoutWrapper>
            <FooterTitle> Our Guarantee </FooterTitle>
            <div> With Veritix, every ticket for every event is backed by our 100% buyer guarantee. That means you’ll always receive a valid and authentic
                ticket in time for your event, or your money back.
            </div>
            <div><LinkElement target={"_blank"} to={"/terms"}> Learn More </LinkElement> about our Privacy Policy, Purchase Agreements, and Terms & Condition.</div>
            <div><ExternalLinkElement target="_blank" href={"https://support.vividseats.com/support/solutions/articles/1000210317-is-my-information-secure-does-the-seller-have-access-to-any-of-my-information-"}> Do Not Sell My Info </ExternalLinkElement></div>
        </LayoutWrapper>
    </FooterElement>
)
export default Footer;
